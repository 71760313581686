import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import ShinyButton from '../../components/ShinyButton';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import { Waypoint } from 'react-waypoint';

import { useForm } from 'react-hook-form';
import './style.scss';

import CareerParallax from '../../components/CareerParallax';
import ConsentCheckbox from '../../components/ConsentCheckbox';

const Careers = () => {
    const { getValues, setValue } = useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFromlocalStorage, setdataFromlocalStorage] = useState();
    const [resumeFileName, setResumeFileName] = useState('');

    useEffect(() => {
        retrieveDataFromLocalStorage();
    }, [currentPage]);

    const retrieveDataFromLocalStorage = () => {
        if (localStorage.getItem('resumeData') !== null) {
            const savedValues = JSON.parse(localStorage.getItem('resumeData'));
            setdataFromlocalStorage(savedValues);
            for (let key in savedValues) {
                setValue(key, savedValues[key]);
            }
        }
    };

    const checkUploadedFileSize = file => {
        if (file.size > 52428800) {
            alert('File is too big! Max resume size is 50MB.');
            file.value = '';
            return false;
        }
        return true;
    };

    const setUploadedFileName = (resume, file) => {
        if (checkUploadedFileSize(file[0])) {
            const selectedFile = file[0].name.split('\\');
            localStorage.setItem('resumeData', JSON.stringify(getValues()));

            if (resume === 'resume') {
                setValue('resume', file);
                setResumeFileName(
                    'File Uploaded: ' + selectedFile[selectedFile.length - 1]
                );
            }
        }
    };

    const uploadResume = () => {
        const resumeUpload = document.getElementById('resume');
        resumeUpload.click();
    };

    return (
        <Layout>
            <Seo title="Careers" />
            <div className="careers-page">
                <ParallaxProvider>
                    <Container className="container-1 purple-blob">
                        <Row className="flexed">
                            <Col>
                                <h1>Join the GlassHive Team</h1>
                            </Col>
                            <Col className="one-half">
                                <div className="image-container ">
                                    <div className="image-1">
                                        <img
                                            className="job-cards"
                                            src={require('../../images/careers/job-cards.png')}
                                            alt="job cards"
                                        ></img>
                                    </div>

                                    <Parallax
                                        y={[100, 20]}
                                        tagOuter="div"
                                        className="layer-3 layered-images"
                                    >
                                        <img
                                            className="main-image"
                                            src={require('../../svgs/careers/shapes.png')}
                                            alt="five star review"
                                        />
                                    </Parallax>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxProvider>
                <Container className="container-2">
                    <Row>
                        <Col className="centered-text">
                            <h2>What is GlassHive?</h2>
                            <p className="hero-subtext">
                                GlassHive is an innovative workOS made for the
                                IT industry. GlassHive was developed by team
                                members who have worked alongside businesses of
                                every size to help clear bottlenecks pertaining
                                to visibility, accountability, and manual data
                                management. We aim to make sales and marketing
                                simple and fun!
                            </p>
                            {/* <div className="button-container">
                                <ShinyButton
                                    className="btn blue"
                                    onClick={() =>
                                        throwSignupConversionEvent(
                                            'https://app.glasshive.com/Account/Login?s=signup'
                                        )
                                    }
                                >
                                    Read Our Story
                                </ShinyButton>
                            </div> */}
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row className="flexed">
                        <Col className="text-container ">
                            <h2>Who we’re looking for</h2>
                            <p className="hero-subtext">
                                First things first…you’ve gotta love movies and
                                comics. If not, chances are you’ll think we’re
                                weird.
                            </p>
                            <p className="hero-subtext">
                                We encourage thinking outside the box and are
                                looking for people who are devoted to becoming
                                experts in their craft. We desire people who
                                want to hone in on their natural strengths and
                                skills – people who share our passion for
                                empowering & growing businesses through our app.
                            </p>
                        </Col>
                        <Col>
                            <div className="image-container">
                                <div className="transparent-hex" />
                                <div className="transparent-hex small" />
                                <img
                                    className="halloween-image-3"
                                    src={require('../../svgs/careers/halloween-2016.png')}
                                    alt="halloween"
                                ></img>
                                <div className="grouped-images">
                                    <img
                                        className="halloween-image"
                                        src={require('../../svgs/careers/ralph-halloween.png')}
                                        alt="halloween"
                                    ></img>
                                    <img
                                        className="halloween-image-2"
                                        src={require('../../svgs/careers/ricky-halloween.png')}
                                        alt="halloween"
                                    ></img>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4 pink-swish-section">
                    <Row>
                        <Col className="perks-and-benefits">
                            <div className="header">
                                <h2>Benefits & Perks</h2>
                            </div>
                            <div className="content">
                                <div className="benefits">
                                    <Waypoint
                                        onEnter={() => {
                                            document
                                                .querySelector('.remote-work')
                                                .classList.add('animate');
                                        }}
                                    />
                                    <div className="benefits-container">
                                        <img
                                            className="remote-work"
                                            src={require('../../images/careers/remote-work.png')}
                                            alt="house"
                                        ></img>
                                        <div className="text-container">
                                            <label>Remote Work</label>
                                            <p>
                                                Work remotely or come into the
                                                office…whatever works best for
                                                you. Our hybrid model gives you
                                                the power to choose. We care
                                                about the work you do, not where
                                                you do it.
                                            </p>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document
                                                .querySelector('.unlimted-pto')
                                                .classList.add('animate');
                                        }}
                                    />
                                    <div className="benefits-container">
                                        <img
                                            className="unlimted-pto"
                                            src={require('../../images/careers/unlimited-pto.png')}
                                            alt="house"
                                        ></img>
                                        <div className="text-container">
                                            <label>Unlimited PTO</label>
                                            <p>
                                                Need a few days off? A week?
                                                Maybe two? We’re flexible and
                                                want to make sure you give us
                                                100% when you’re working so take
                                                the time off when you need it.
                                            </p>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document
                                                .querySelector('.healthcare')
                                                .classList.add('animate');
                                        }}
                                    />
                                    <div className="benefits-container">
                                        <img
                                            className="healthcare"
                                            src={require('../../images/careers/heart.png')}
                                            alt="house"
                                        ></img>
                                        <div className="text-container">
                                            <label>Healthcare</label>
                                            <p>
                                                Your health matters to us. You
                                                get access to medical, dental,
                                                and vision insurance for you and
                                                your dependents.
                                            </p>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document
                                                .querySelector('.canvas-day')
                                                .classList.add('animate');
                                        }}
                                    />
                                    <div className="benefits-container">
                                        <img
                                            className="canvas-day"
                                            src={require('../../images/careers/canvas-days.png')}
                                            alt="house"
                                        ></img>
                                        <div className="text-container">
                                            <label>Canvas Days</label>
                                            <p>
                                                2 days a year where we get
                                                together as a team and focus on
                                                improving GlassHive with
                                                innovative ideas. Food and
                                                treats provided.
                                            </p>
                                        </div>
                                    </div>
                                    <Waypoint
                                        onEnter={() => {
                                            document
                                                .querySelector('.team-events')
                                                .classList.add('animate');
                                        }}
                                    />
                                    <div className="benefits-container">
                                        <img
                                            className="team-events"
                                            src={require('../../images/careers/team-events.png')}
                                            alt="house"
                                        ></img>
                                        <div className="text-container">
                                            <label>Team Events</label>
                                            <p>
                                                Not just limited to an annual
                                                Christmas party. We’re talking
                                                about going to the movies, game
                                                nights at D&Bs, or just playing
                                                games together online. We’re
                                                down for whatever sounds like a
                                                good time.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5 ">
                    <Row className="header">
                        <Col>
                            <div className="header">
                                <h2>Our Core Values</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="flexed magic">
                        <Col className="text-container one-third">
                            <div className="description">
                                <h3>Create Magic</h3>
                                <p className="hero-subtext">
                                    The impulse to create something new and
                                    unique drives us to do what we do. We aim to
                                    produce magic and wonder in every action we
                                    take. Our art inspires, guides action, and
                                    produces tangible results.
                                </p>
                            </div>
                        </Col>
                        <Col className="one-third">
                            <CareerParallax
                                imageUrl={require('../../images/careers/create-magic.png')}
                                position="right"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="one-third">
                            <CareerParallax
                                imageUrl={require('../../images/careers/the-answer.png')}
                                position="left"
                            />
                        </Col>
                        <Col className="text-container one-third">
                            <div className="description">
                                <h3>Be the Answer</h3>
                                <p className="hero-subtext">
                                    We strive to make big waves and often find
                                    ourselves tackling challenges we don't
                                    immediately have a solution for. We are
                                    motivated by solving problems and will
                                    always be the bridge that gets us to our
                                    desired destination.
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="flexed">
                        <Col className="text-container one-third">
                            <div className="description">
                                <h3>Embrace Change</h3>
                                <p className="hero-subtext">
                                    We adapt and thrive under any circumstances
                                    in order to be the change we want to see.
                                    While our processes and environments may
                                    change, we remain consistent in the level of
                                    effort, strength of will, and amount of
                                    determination we have to complete our team
                                    and individual goals.
                                </p>
                            </div>
                        </Col>
                        <Col className="one-third">
                            <CareerParallax
                                imageUrl={require('../../images/careers/bee-change.png')}
                                position="right"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="one-third">
                            <CareerParallax
                                imageUrl={require('../../images/careers/Egg.png')}
                                position="left"
                            />
                        </Col>
                        <Col className="text-container one-third">
                            <div className="description">
                                <h3>Break Eggs</h3>
                                <p className="hero-subtext">
                                    Creating something meaningful and innovative
                                    doesn’t come without frequent failure. We
                                    embrace failure, whether it is one or many.
                                    As long as you have the right attitude and a
                                    willingness to learn from it, you are
                                    guaranteed success.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6">
                    <Row>
                        <Col className="two-thirds">
                            <div className="content">
                                <h3>Let’s Work Together</h3>
                                <p>
                                    Browse our openings and if it seems like the
                                    right fit for you, submit your video resume
                                    and apply. (This applies to all positions –
                                    not just Video Editors.)
                                </p>
                                <p>
                                    It doesn’t have to be Spielberg quality.
                                    We’re just looking for personality.
                                </p>
                                <p>
                                    *We want actual video resumes, not links to
                                    YouTube channels or videos created for other
                                    reasons.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-7">
                    <Row>
                        <Col>
                            <div className="text-container">
                                <h3>Senior Graphic Designer</h3>
                                <p>
                                    We're looking for a visual savant who can
                                    tell stories through imagery.
                                </p>
                            </div>
                        </Col>
                        <Col className="button-container">
                            <div>
                                <ShinyButton
                                    className="btn blue"
                                    href="https://glasshive.com/senior-graphic-designer"
                                >
                                    View Position
                                </ShinyButton>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {/* <Container className="container-7-2">
                    <Row>
                        <Col>
                            <div className="text-container">
                                <h3>QA Automation Engineer  </h3>
                                <p>
                                    We're seeking a QA expert with 3+ years of
                                    experience to create automated regression
                                    and load tests, perform manual testing, and
                                    collaborate with our development team to
                                    ensure product quality.
                                </p>
                            </div>
                        </Col>
                        <Col className="button-container">
                            <div>
                                <ShinyButton
                                    className="btn blue"
                                    href="https://glasshive.com/qa-automation-engineer"
                                >
                                    View Position
                                </ShinyButton>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
                <Container className="container-7-3">
                    <Row>
                        <Col>
                            <div className="text-container">
                                <h3>Motion Designer  </h3>
                                <p>
                                    We're looking to hire a Motion Designer to
                                    join our team at GlassHive, a leading work
                                    OS in the IT channel. As a Motion Designer,
                                    you will be expected to design, edit, and
                                    update all multimedia assets. This
                                    individual must have a passion for and past
                                    experience in motion graphics, design, and
                                    video editing. In addition, the motion
                                    designer is responsible for the development
                                    and execution of related digital assets,
                                    audio/video assets, and animations. They
                                    will also be responsible for assembling
                                    recorded footage into a finished project
                                    that matches the team’s vision and is
                                    suitable for marketing. The goal is to bring
                                    sight and sound together to tell a cohesive
                                    story.
                                </p>
                            </div>
                        </Col>
                        <Col className="button-container">
                            <div>
                                <ShinyButton
                                    className="btn blue"
                                    href="https://glasshive.com/motion-designer"
                                >
                                    View Position
                                </ShinyButton>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className="container-8">
                    <Row>
                        <AppForm
                            formName="careers-form"
                            submitBtnText="Send"
                            className="pink-form careers-form"
                            formCategory="Careers"
                        >
                            <AppInput
                                fieldName="firstName"
                                type="text"
                                labelText="What's your name?"
                                requiredField
                            />
                            <AppInput
                                fieldName="email"
                                type="email"
                                labelText="What's your email address?"
                                requiredField
                            />
                            <AppInput
                                fieldName="phone"
                                type="text"
                                labelText="What's your phone number?"
                                requiredField
                            />
                            <AppInput
                                fieldName="position"
                                type="text"
                                labelText="What position are you applying for?"
                                requiredField
                            />
                            <div className="file centered-text">
                                <label
                                    htmlFor="resume"
                                    className="custom-file-upload"
                                >
                                    <input
                                        type="file"
                                        name="resume"
                                        id="resume"
                                        className="file-upload"
                                        required
                                        onChange={e => {
                                            setUploadedFileName(
                                                'resume',
                                                e.target.files
                                            );
                                        }}
                                    />
                                    <p className="choose-a-file">
                                        Drop video resume/cover letter here or
                                        <ShinyButton
                                            className="btn white create-free-account-button"
                                            onClick={() => uploadResume()}
                                        >
                                            Select Files
                                        </ShinyButton>
                                    </p>
                                    <p id="fileUploadedName">
                                        {resumeFileName}
                                    </p>
                                </label>
                            </div>
                            <AppInput
                                fieldName="video"
                                type="text"
                                labelText="Video or Portfolio URL"
                            />
                            <div style={{ marginBottom: 20 }}>
                                <ConsentCheckbox />
                            </div>
                        </AppForm>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Careers;
